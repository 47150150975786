import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PartyList from "../../components/modals/partyListModal";
import AddRoutePlan from "../../components/modals/addRoutePlan";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
// );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function AuditLogs() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
const [filterState, setFilterState]= useState(null)
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal,setModal]=useState(false)
  const [modalData,setModalData]=useState()
  const [addModal,setAddModal] = useState(false)
  const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()


  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    if(!filter){
    fetchOrder()}
  }, [paginationModel.page]);
  useEffect(() => {
    if(filter){
      onFilterChange(searchTerm)
    }
  }, [paginationModel.page]);

  const fetchOrder= async (val= null) => {
    try {
      if(filterState)
      val = filterState
      setLoading(true);
      let results = await window.Platform.database.fetchLogs({pageNumber:paginationModel.page, filterVal: val });
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));


        console.log("tottal", results.count)
        setRowCount(results.count)

// setRowCount(results.count)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }

  }

  const onFilterChange= async(filterModel) => {    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterRoutePlan({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterRoutePlan({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };
  

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

  let result = [
    // {
    //     field: "userId",
    //     headerName: "User Id",
    //     sortable: false,
    //     width: 150,
    //   // filterable: false,

        
    //     editable: false,
    //     renderCell: (params) => {
  
          
    //       let name = params.row?.userId||"N/A"
  
    //       return (
  
    //         <Tooltip title={name || "N/A"}>
  
    //           <Typography variant="inherit">{name}</Typography>
  
    //         </Tooltip>
  
    //       )
  
    //     },
    //   },

      {
        field: "method",
        headerName: "Method Type",
        sortable: false,
        width: 150,
      // filterable: false,

        
        editable: false,
        valueGetter: (params) => {
          return (params?.row?.method || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.method||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "path",
        headerName: "Path",
        sortable: false,
        width: 350,
      // filterable: false,

        
        editable: false,
        valueGetter: (params) => {
          return (params?.row?.path || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.path||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "body",
        headerName: "Params",
        sortable: false,
        width: 350,
      // filterable: false,

        
        editable: false,
        valueGetter: (params) => {
          return (params?.row?.body || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.body||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        sortable: false,
        width: 200,
      // filterable: false,

        
        editable: false,
        valueGetter: (params) => {
          return (formatTimeStamp(params.row?.createdAt) || "N/A");
        },
        renderCell: (params) => {
  
          let name = formatTimeStamp(params.row?.createdAt)||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
   
 
  ];


  return result
}

const approveRejectOrder=async(val)=>{
    console.log("VALuesss",val)
try{
    setLoading(true)
  const results=await window.Platform.database.approveRoute(val)
    window.NotificationUtils.showSuccess("Order Update Success")
    setLoading(false)
    fetchOrder()

}
catch(err){
console.log(err)
window.NotificationUtils.showError("Something Went Wrong!")
setLoading(false)

}
}

const formatTimeStamp = (timestampFromDB) => {
  const options = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,  // Use 12-hour clock
  };

  const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);

  return formattedDate;
};



const formatToOnlyDate=(val)=>{
    const concertDate = new Date(val);

// Extracting date components
const year = concertDate.getFullYear();
const month = concertDate.getMonth() + 1; // Months are zero-indexed, so we add 1
const day = concertDate.getDate();

// Creating a formatted date string
const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

console.log(formattedDate);
return formattedDate;
}

const getRouteplanById=async(val,val1)=>{
try{
    setLoading(true)
    setModalData()
let sentData={id:val}
    let results = await window.Platform.database.getRouteplanById(sentData);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

setModalData({data:jsonArrayWithId,id:val,date:val1})
setModal(true)
    setLoading(false)

}
catch(error){
    console.log(error)
    window.NotificationUtils.showError("Something Went Wrong!")
    setLoading(false)



}



}








  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-audit-logs",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          pageSizeOptions={[10]}
          // onFilterModelChange={(val)=>
          //     {
          // //       console.log("vvvv",val);
          //   setFilterState(val)
          // fetchOrder(val)
            
          //   // if(val?.items[0]?.value?.length>0){
          //   //   onFilterChange(val.items[0])
          //   //   setSearchTerm(val.items[0])
          //   // }
          //   // else if(!val.items[0]?.value   && startDate && endDate){
          //   //   setSearchTerm(null)
          //   //   onFilterChange()
          //   //   console.log("CHECKING...1")

          //   // }
          //   // else{
          //   //   fetchOrder()
          //   //   setSearchTerm(null)
          //   //   setFilter(false)
          //   // }
          // //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          // //     setSearchTerm(null)
          // //     fetchData()
          // //     console.log("CHECK1")
          // //   }
          // //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          // //     setSearchTerm(null)
  
          // //     onFilterChange1()
          // //     console.log("checkterr")
          // //   }
          // //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          // //     setSearchTerm(null)
          // //     console.log("check2")
          // //     onFilterChange1()
  
  
          // //   }
          // //   else{
          // //     setSearchTerm(null)
          // //     console.log("check2")
          // //     onFilterChange1()
          // //   }
          // }
            
          // }
          // filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const formatDate=async(data)=>{
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
  
  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
  setStartDate1(formattedDate)
  setStartDate(formattedDate)
  console.log("CHECK!!")
  console.log("CHECKENDDATE",endDate)
  
  }
  const finalDateRangeFilter=async(data)=>{
    try{
      setLoading(true)
      setFilter(true)
  
    const datePickerResponse = new Date(data);
  
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
    
    if (data){
    setEndDate(formattedDate)
    setEndDate1(formattedDate)
  }
      if(searchTerm){
      const response = await window.Platform.database.filterRoutePlan({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
       
    }
      else{
      const response = await window.Platform.database.filterRoutePlan({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      
      }
      setLoading(false)
  
    }
    catch(err){
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
  
      fetchOrder()
    
    }
  }
  const clearDateFilter=async()=>{
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if(searchTerm){
      try{
      setLoading(true)
      const response = await window.Platform.database.filterRoutePlan({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
        
      setLoading(false)
    }
    catch(e){
      console.log(e)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      fetchOrder()  
  
      }
    }
  else{
    setFilter(false)
    fetchOrder()
  }
  }



  return (
    <>
    
      <StaffEditorPageContainer>
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          {loading && <OpaqueLoading/>}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button> */}


            </div>
            

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {/* {
      modal &&
      <PartyList
      onCancel={() => setModal(null)}
      loading={loading}
      data={modalData}
    //   onSubmit={updateWarehouseData}
      />
    } */}
    {/* {
      addModal && <AddRoutePlan onClose={()=>setAddModal(false)} fetchData={()=>fetchOrder()} />
    } */}
    </>
  );
}
