import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Link from '@mui/material/Link';

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 19px;
`;
 
 
const DistributorModal = ({ props,onClose,onClose1,remarks,dataProp,id,distributorId,fetchData,status}) => {
  console.log("DATA0PROPA",dataProp);
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  // console.log("DATAAUTH",auth.user.plant_master__c );
 
  // const [details, setDetails] = useState(data);

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState()
  // const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [errors, setErrors] = useState({});
 const [form,setForm]=useState({distributorName:dataProp?.DistributorName||  "",
  gstNo:dataProp?.GSTno||"",
  makerName:dataProp?.makerName||  "",
  makerContact:dataProp?.makerContact||"",
  ownerId:dataProp?.ownerId||"",
  pincode:dataProp?.Pincode||"",
  state:dataProp?.State||"",
  district:dataProp?.District||"",
  addressLine1:dataProp?.AddressLine1||"",
  addressLine2:dataProp?.AddressLine2||"",
  landmark:dataProp?.Landmark||"",
  subDistributorName:dataProp?.subDistributorName||"",
  makerRole:dataProp?.decisionMakerRole||"",
  panNumber:dataProp?.panNumber||"",
  kyc:dataProp?.kycRecieved||"",
  bankName:dataProp?.bankName||"",
  holderName:dataProp?.accountHolderName||"",
  branchName:dataProp?.branchName||"",
  accountNo:dataProp?.accountNo||"",
  ifsc:dataProp?.ifscCode||"",
  billingPincode:dataProp?.billingPincode||"",
  billingState:dataProp?.billingState||"",
  billingCity:dataProp?.billingCity||"",
  billingAddressLine1:dataProp?.billingAddressLine1||"",
  billingAddressLine2:dataProp?.billingAddressLine2||"",
  shippingPincode:dataProp?.shippingPincode||"",
  shippingState:dataProp?.shippingState||"",
  shippingCity:dataProp?.shippingCity||"",
  shippingAddressLine1:dataProp?.shippingAddressLine1||"",
  shippingAddressLine2:dataProp?.shippingAddressLine2||"",
  email:dataProp?.email||"",
  transporterId: "" || dataProp?.transporterId
})
const [fileUrl, setFileUrl] = useState();
 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState(null)
 const [contactPersonRole,setContactPersonRole]=useState([])

  const [transporter, setTransporter] = useState([]);
  const [transporterForm, setTransporterForm] = useState({});
  const [showTransporterForm, setShowTransporterForm] = useState(false);
  const [transporterErrors, setTransporterErrors] = useState({});
  const [accessChanges, setAccessChanges] = useState("");

  const [isCancelChequeUploaded, setIsCancelChequeUploaded] = useState(false);
  const [isSchoolPhotoUploaded, setIsSchoolPhotoUploaded] = useState(false);
  const [isPanCardUploaded, setIsPanCardUploaded] = useState(false);
  const [isContactPersonUploaded, setIsContactPersonUploaded] = useState(false);
  const [isGSTCertificateUploaded, setIsGSTCertificateUploaded] = useState(false);
  const [isShopPhotoUploaded, setIsShopPhotoUploaded] = useState(false);


  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  console.log("FORMM",form)
 
useEffect(()=>{
// if(tabValue===1){
//   fetchProduct(plantCode,distributorId)
// }
fetchEmployees()
getContactPersonRole()
console.log("currentTab", tabValue)
  getTransporters();
},[])

  const validateForm = (data) => {
    const gstRegex = /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const errors = {};

    if (!data.ownerId?.trim()) {
      errors.assignTo = "Assign to is required";
    }

    if (!data.distributorName?.trim()) {
      errors.distributorName = "Distributor name is required";
    }

    if (data.gstNo?.trim()?.length > 0) {
      if (data.gstNo?.trim().length < 15) {
        errors.gstNo = "GST number must be 15 characters long."
      } else if (!gstRegex.test((data.gstNo?.trim()))) {
        errors.gstNo = "Please type valid GST number";
      }
    }
    
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }

    if (!data.bankName?.trim()) {
      errors.bankName = "Bank name is required";
    }

    if (!data.accountNo?.trim()) {
      errors.accountNo = "Account number is required";
    }

    if (!data.branchName?.trim()) {
      errors.branchName = "Branch name is required";
    }

    if (!data.holderName?.trim()) {
      errors.holderName = "Holder name is required";
    }

    if (!data.ifsc?.trim()) {
      errors.ifsc = "IFSC is required";
    }else if (!ifscRegex.test((data.ifsc?.trim()?.toUpperCase()))) {
      errors.ifsc = "Please type valid IFSC Code";
    }

    if (!data.kyc?.trim()) {
      errors.kyc = "KYC is required";
    }
    
    if (!data.billingPincode?.trim()) {
      errors.billingPincode = "Pin code is required";
    } else if (data.billingPincode.length < 6) {
      errors.billingPincode = "Pin should be 6 digits long.";
    }

    if (!data.billingAddressLine1?.trim()) {
      errors.billingAddressLine1 = "Billing address line1 is required";
    }
    
    if (!data.shippingPincode?.trim()) {
      errors.shippingPincode = "Pin code is required";
    } else if (data.shippingPincode.length < 6) {
      errors.shippingPincode = "Pin should be 6 digits long.";
    }

    if (!data.shippingAddressLine1?.trim()) {
      errors.shippingAddressLine1 = "Shipping address line1 is required";
    }

    if (!data.pincode?.trim()) {
      errors.pincode = "Pin code is required";
    } else if (data.pincode.length < 6) {
      errors.pincode = "Pin should be 6 digits long.";
    }

    if (!data.addressLine1?.trim()) {
      errors.addressLine1 = "Address line1 is required";
    }

    if (!data.transporterId?.trim()) {
      errors.transporterId = "Transporter is required";
    }
      
    return errors;
  };

  /* Transporter Logic */

  const getTransporters = async () => {
    try {

      setLoading(true);
      let results = await window.Platform.database.getTransporters({forDD:true});
      setTransporter(results.data);
      
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      setAccessChanges(jsonArrayWithId);
      setLoading(false);

    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const manageAddTransporterTab = (event) => {
    event.preventDefault();
    setShowTransporterForm(true);
    setTabValue(2);
  };

  const validateFormTransporter = (data) => {
    const errors = {};

    if (!data.transporterName?.trim()) {
      errors.transporterName = "Transporter name to is required";
    }

    if (!data.completeAddress?.trim()) {
      errors.completeAddress = "Complete address to is required";
    }

    if (!data.pincode?.trim()) {
      errors.pincode = 'Pin is required';
    } else if (data.pincode?.length < 6) {
      errors.pincode = 'Pin code must be exactly 6 digits long.';
    }

    if (!data.phone?.trim()) {
      errors.phone = "Phone is required";
    } else if (data.phone?.length < 10) {
      errors.phone = "Phone must be exactly 10 digits long.";
    }

    if (!data.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }

    if (!data.gst?.trim()) {
      errors.gst = 'GST is required';
    } else if (!/^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst)) {
      errors.gst = 'GST is invalid';
    }

    if (!data.contactPersonContactNo?.trim()) {
      errors.contactPersonContactNo = "Contact person number is required";
    }

    if (!data.servingStates?.trim()) {
      errors.servingStates = "Serving states is required";
    }

    return errors;
  };

  const onsubmitTransporter = async (data) => {
    try {
      const errors = validateFormTransporter(data);
      setTransporterErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true);

        const results = await window.Platform.database.addTransporter(data);

        if(results.status) {
          window.NotificationUtils.showError("Please fill all required fields.");
          setLoading(false);
          return;
        }

        await getTransporters();
        changeHandler("transporterId", results.data.transporterId);
        setTabValue(1);
        setShowTransporterForm(false);
        window.NotificationUtils.showSuccess("Transporter added successfully!");
        setLoading(false);
        // onClose();
        // fetchData();
      } else {
        window.NotificationUtils.showError("Please fill all required fields.");
        setLoading(false);
      }
    } catch (error) {
      window.NotificationUtils.showError(error.response.data.message);
      console.log(error);
      setLoading(false);
    }
  };

  const changeHandlerTransporter = (fieldName, value) => {
    let val = value;
    if (fieldName === "phone" || fieldName === "contactPersonContactNo") {
      val = value.replace(/[^0-9]/g, '').slice(0, 10);
    }
    setTransporterForm((prevForm) => ({ ...prevForm, [fieldName]: val }));
  };

  const renderTransporterForm = () => {
    return (
      <div style={{ overflowY: "auto" }}>
        <Stack direction={"row"} spacing={2} padding={2}></Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Transporter Name"
            value={transporterForm?.transporterName || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("transporterName", e.target.value)}
            required
            error={transporterErrors.transporterName ? true : false}
          />

          <TextField
            label="Complete Address"
            value={transporterForm?.completeAddress || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("completeAddress", e.target.value)}
            required
            error={transporterErrors.completeAddress ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="PIN code"
            value={transporterForm?.pincode || ""}
            type="text"
            fullWidth
            onChange={async (e) => {
              const inputValue = e.target.value;
              const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 6);
              changeHandlerTransporter("pincode", sanitizedValue);
              if (sanitizedValue.length === 6) {
                await fetchPinCode(sanitizedValue, 'transporter');
              }
            }
            }
            required
            error={transporterErrors.pincode ? true : false}
          />

          <TextField
            label="State"
            value={transporterForm?.state || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("state", e.target.value)}
            disabled
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Phone"
            value={transporterForm?.phone || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("phone", e.target.value)}
            required
            error={transporterErrors.phone ? true : false}
            inputProps={{ maxLength: 10 }}
          />

          <TextField
            label="Email"
            value={transporterForm?.email || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("email", e.target.value)}
            required
            error={transporterErrors.email ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="Contact Person Name"
            value={transporterForm?.contactPersonName || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("contactPersonName", e.target.value)}
          />

          <TextField
            label="Contact Person Number"
            value={transporterForm?.contactPersonContactNo || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("contactPersonContactNo", e.target.value)}
            inputProps={{ maxLength: 10 }}
            required
            error={transporterErrors.contactPersonContactNo ? true : false}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <TextField
            label="GST"
            value={transporterForm?.gst || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("gst", e.target.value)}
            inputProps={{ maxLength: 15, style: { textTransform: "uppercase" } }}
            required
            error={transporterErrors.gst ? true : false}
          />

          <TextField
            label="Serving States"
            value={transporterForm?.servingStates || ""}
            type="text"
            fullWidth
            onChange={(e) => changeHandlerTransporter("servingStates", e.target.value)}
            required
            error={transporterErrors.servingStates ? true : false}
          />
        </Stack>

        <ButtonContainer>
          <Button variant="contained" onClick={() => onsubmitTransporter(transporterForm)}>
            Create
          </Button>
        </ButtonContainer>
      </div>
    )
  }
 
const changeHandler = (fieldName, value) => {
  if (fieldName === 'accountNo') {
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 17);
      setForm((prevErrors) => ({
        ...prevErrors,
        accountNo: sanitizedValue,
      }));
      return;
  }

  if(fieldName==="makerContact" ||fieldName==="strength" ){
    console.log("FIELDNAME",fieldName)
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  }
  else{
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  }
    // console.log(value)
  };

  const schoolBoard=[{id:1,value:"CBSE"},{id:2,value:"ICSE"},{id:3,value:"State Board"}]
  const schoolMedium=[{id:1,value:"Hindi"},{id:2,value:"English"}]

const renderContent1=()=>{
  return (
    <div style={{ overflowY:"auto" }}>
    <Stack direction={"row"} spacing={2} padding={2}>
    <Autocomplete
            options={employee}
            // getOptionLabel={}
            disabled={loading}

            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                variant="outlined"
                error={errors.assignTo}
                required
              />
            )}/>
   <TextField label="Distributor Name" value={form?.distributorName || ""} required type="text" fullWidth onChange={(e)=>changeHandler("distributorName",e.target.value)} error={errors.distributorName}/>
          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Sub Distributor Name" value={form?.subDistributorName || ""} type="text" fullWidth onChange={(e) => changeHandler("subDistributorName", e.target.value)} />
            <TextField label="Decision Maker Name" value={form?.makerName || ""} type="text" fullWidth onChange={(e) => changeHandler("makerName", e.target.value)} />
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Decision Maker Mobile No" value={form?.makerContact || ""} type="text" fullWidth onChange={(e) => changeHandler("makerContact", e.target.value)} />
            <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "100%" }}>
              <InputLabel style={{ background: 'white' }} >Decision Maker Role</InputLabel>
              <Select
                labelId={`Product`}
                label="School Board"
                value={form?.makerRole || ""}
                onChange={(e) => changeHandler("makerRole", e.target.value)}
              >
                {contactPersonRole?.map((option, optionIndex) => (
                  <MenuItem key={option.contactPersonRoleId} value={option.roleName}>
                    {option.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="GST No" value={form?.gstNo || ""} type="text" fullWidth onChange={(e)=>changeHandler("gstNo",e.target.value)} error={errors.gstNo} inputProps={{ maxLength: 15, style: { textTransform: "uppercase" } }} />
          <TextField label="PAN No" value={form?.panNumber || ""} type="text" fullWidth onChange={(e)=>changeHandler("panNumber",e.target.value)} inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}/>
          </Stack>
<Stack direction={"row"} spacing={2} padding={2} >
<TextField label="Email" value={form?.email || ""} type="text" fullWidth onChange={(e)=>changeHandler("email",e.target.value)} error={errors.email}/>

<FormControl required variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel style={{ background: 'white' }} >KYC Recieved</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            
            value={form?.kyc||""}
            onChange={(e) => changeHandler("kyc",e.target.value)}
            required
            error={errors.kyc}
          >
           
    <MenuItem value={"1"}>
      Yes
    </MenuItem>
    <MenuItem value={"0"}>
    No
  </MenuItem>
 
          </Select>
        </FormControl>
</Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
<strong>Bank Details</strong>

</Stack>
<Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Bank Name" value={form?.bankName || ""} required type="text" fullWidth onChange={(e)=>changeHandler("bankName",e.target.value) } error={errors.bankName} />
          <TextField label="Bank Account Holder name" value={form?.holderName || ""} required type="text" fullWidth onChange={(e)=>changeHandler("holderName",e.target.value) } error={errors.holderName} />    

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Branch Name" value={form?.branchName || ""} type="text" required fullWidth onChange={(e)=>changeHandler("branchName",e.target.value) } error={errors.branchName} />
    
    <TextField label="Bank Account Number" value={form?.accountNo || ""} type="text" required fullWidth onChange={(e)=>changeHandler("accountNo",e.target.value) } error={errors.accountNo} />
    </Stack>

    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField label="IFSC Code" value={form?.ifsc || ""} type="text" fullWidth required onChange={(e)=>changeHandler("ifsc",e.target.value) } error={errors.ifsc} />
    </Stack>

        
             
         
     
      
</div>
)}

const detailUpdate = (fieldName, value) => {
  setForm({
    [fieldName]: value
  })
}

const uploadFile = async(type, val) => {
  // const formData = new FormData();
  // const image = {
  //   uri: val,
  //   type: 'image/jpeg',
  //   name: 'captured_image.jpg',
  // };
  // formData.append('file', image);
  // formData.append('filename', 'captured_image.jpg');
  
  try {
    console.log("VALUE IMAGE",val)
    const formData = new FormData();
    formData.append('file', val);
    formData.append('dashboard', true);

    let upload = await window.Platform.database.uploadImageApi(formData);

    if (upload[0]) {
      const { status, message, link } = upload[0];
      console.log("LINK VIEW", link);

      // If the status is false, proceed with file upload to S3
      if (!status) {
        // try {
        //   // Log the file and presigned URL to check their validity
        //   // console.log("Uploading file:", imageFile);
        //   console.log("Presigned URL:", link.presignedUrl);

        //   // Set the correct headers for the PUT request
        //   const headers = {
        //     'Content-Type': val.type, // Dynamically use the file's type
        //   };

        //   // Upload the file to S3 via the presigned URL
        //   const response = await axios.put(link.presignedUrl, val, {
        //     headers: headers,
        //   });

        //   // Check if the upload was successful
        //   if (response.status === 200) {
        //     setFileUrl(link.fileUrl);  // Set the uploaded file URL
        //     console.log('File uploaded successfully!');
        //   } else {
        //     console.error('Upload failed:', response);
        //   }
        // } catch (error) {
        //   console.error('Upload error:', error);
        // }
      } else {
        console.log(message); // Handle message if status is true
      }

    switch (type) {
      case 'cancelCheck':
        detailUpdate("chequePic", link.fileUrl);
        setIsCancelChequeUploaded(true);
        break;
      case 'gstCertificate':
        detailUpdate("gstPic", link.fileUrl);
        setIsGSTCertificateUploaded(true);
        break;
      case 'shopPhoto':
        detailUpdate("shopPic", link.fileUrl);
        setIsShopPhotoUploaded(true);
        break;
      case 'panCard':
        detailUpdate("panPic", link.fileUrl);
        setIsPanCardUploaded(true);
        break;
      case 'personPhoto':
        detailUpdate("contactPersonPic", link.fileUrl);
        setIsContactPersonUploaded(true);
        break;
    }
  }
  } catch (error) {
    console.log(error?.message);
  }
}

const renderContent2 = (params) => {
  return (
    <div style={{ maxHeight: "510px", height: "505px", overflowY: "auto" }}>
      <Stack direction={"row"} spacing={2} padding={2}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          style={{ display: 'none ' }}
          id="cancel-check"
          onChange={(e) => {
            uploadFile('cancelCheck',  e.target.files[0]);
          }}
        />
        <label htmlFor="cancel-check" style={{ marginLeft: '0px' }}>
          <Button
            label="Choose File"
            value={form?.chequePic || ''}
            fullWidth
            variant="outlined"
            onClick={() => document.getElementById('cancel-check').click()}
          >
            Upload Cancel Check
          </Button>
        </label>

        {isCancelChequeUploaded && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            <span>Cancel Check Uploaded Successfully</span>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          style={{ display: 'none ' }}
          id="gst-certificate"
          onChange={(e) => {
            // uploadFile('gstCertificate', { file: e.target.files[0], filename: "image" });
            uploadFile('gstCertificate', e.target.files[0]);

          }}
        />
        <label htmlFor="gst-certificate" style={{ marginLeft: '0px' }}>
          <Button
            label="Choose File"
            value={form?.gstPic || ''}
            fullWidth
            variant="outlined"
            onClick={() => document.getElementById('gst-certificate').click()}
          >
            Upload GST Certificate
          </Button>
        </label>

        {isGSTCertificateUploaded && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            <span>GST Certificate Uploaded Successfully</span>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          style={{ display: 'none ' }}
          id="shop-photo"
          onChange={(e) => {
            // uploadFile('shopPhoto', { file: e.target.files[0], filename: "image" });
            uploadFile('shopPhoto', e.target.files[0]);

          }}
        />
        <label htmlFor="shop-photo" style={{ marginLeft: '0px' }}>
          <Button
            label="Choose File"
            value={form?.shopPic || ''}
            fullWidth
            variant="outlined"
            onClick={() => document.getElementById('shop-photo').click()}
          >
            Upload Shop/Office Photo
          </Button>
        </label>

        {isShopPhotoUploaded && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            <span>Shop/Office Photo Uploaded Successfully</span>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          style={{ display: 'none ' }}
          id="pan-card"
          onChange={(e) => {
            // uploadFile('panCard', { file: e.target.files[0], filename: "image" });
            uploadFile('panCard', e.target.files[0]);

          }}
        />
        <label htmlFor="pan-card" style={{ marginLeft: '0px' }}>
          <Button
            label="Choose File"
            value={form?.panPic || ''}
            fullWidth
            variant="outlined"
            onClick={() => document.getElementById('pan-card').click()}
          >
            Upload PAN Card/ Affiliation Photo
          </Button>
        </label>

        {isPanCardUploaded && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            <span>PAN Card Uploaded Successfully</span>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          style={{ display: 'none ' }}
          id="person-photo"
          onChange={(e) => {
            // uploadFile('personPhoto', { file: e.target.files[0], filename: "image" });
            uploadFile('personPhoto', e.target.files[0]);

          }}
        />
        <label htmlFor="person-photo" style={{ marginLeft: '0px' }}>
          <Button
            label="Choose File"
            value={form?.contactPersonPic || ''}
            fullWidth
            variant="outlined"
            onClick={() => document.getElementById('person-photo').click()}
          >
            Contact Person Photo
          </Button>
        </label>

        {isContactPersonUploaded && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
            <CheckCircleIcon sx={{ marginRight: 1 }} />
            <span>Contact Person Image Uploaded Successfully</span>
          </Box>
        )}
      </Stack>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding:"5px" }}>
       {status? <Button variant="contained"  onClick={()=>onsubmit(form)} >
            Create
        </Button>:<Button variant="contained" onClick={()=>onEdit(form)} >

        Update
        </Button>}
        </div>
    </div>
  );
};

const handleCheckbox=(val)=>{

setChangePassword(val)
console.log("CHECKVAL",val)
changeHandler("onboardforErp",val)

}

const formatDate=(data)=>{
    console.log("DATAAAA",data.$d    )
    const timestamp = new Date(data.$d);

const unixTimestamp = Math.floor(timestamp.getTime() / 1000);

changeHandler("incorporationDate",unixTimestamp)
console.log("UNIX",unixTimestamp);
}

const getContactPersonRole=async(data)=>{
  try{
    setLoading(true)
    const results = await window.Platform.database.getContactPersonRole();
    setContactPersonRole(results.data)
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)

  }
}
  const renderContent = (params) => {
    console.log("CheckTeabRenderContent",tabValue)
    return (
      <>
      <div style={{ overflowY:"auto" }}>
       
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Pincode" required value={form?.pincode || ""} type="text" fullWidth 
           
           onChange={async(e) => {
            const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 6);
            
            if (sanitizedValue.length <= 7) {
              if (sanitizedValue.length === 6) {
        console.log("logg", sanitizedValue);
        await fetchPinCode(sanitizedValue);
      }
       changeHandler("pincode", sanitizedValue);
    }
  
          }}
          error={errors.pincode}
           />
          <TextField label="State" required InputLabelProps={{
            shrink: true,
          }} disabled value={form?.state} type="text" fullWidth />
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="District" required InputLabelProps={{
              shrink: true,
            }} disabled value={form?.district} type="text" fullWidth />
          

<TextField label="Address Line 1" value={form?.addressLine1} required type="text" fullWidth onChange={(e)=>changeHandler("addressLine1",e.target.value)} error={errors.addressLine1}/>

</Stack>
            <Stack direction={"row"} spacing={2} padding={2}>
              <TextField label="Address Line 2" value={form?.addressLine2} type="text" fullWidth onChange={(e)=>changeHandler("addressLine2",e.target.value)}/>
              <TextField label="Landmark" value={form?.landmark} type="text" fullWidth onChange={(e)=>changeHandler("landmark",e.target.value)}/>
            </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <strong>Select Transporter</strong>
          </Stack>

          {/* <Stack direction={"row"} spacing={4} padding={2}>
            <Autocomplete
              options={transporter}
              disabled={loading}
              getOptionLabel={(option) => `${option?.transporter_name}`}
              getOptionValue={(option) => option?.transporterId || ''}
              style={{ width: "100%" }}
              value={form.transporterId}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                changeHandler("transporterId", value?.transporterId);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Transporter"}
                  variant="outlined"
                />
              )} />

            <Link sx={{ width: 200, display: 'flex', alignItems: 'center' }} href="#" underline="none" onClick={manageAddTransporterTab}>
              Add Transporter
            </Link>
          </Stack> */}

          <Stack direction={"row"} spacing={4} padding={2}>
      <FormControl fullWidth variant="outlined" disabled={loading}>
        <InputLabel>Select Transporter</InputLabel>
        <Select
          value={form.transporterId || ''}
          onChange={(event) => {
            const selectedValue = event.target.value;
            changeHandler("transporterId", selectedValue);
          }}
          label="Select Transporter"
          required
          error={errors.transporterId}
        >
          {transporter.length > 0 ? (
            transporter.map((option) => (
              <MenuItem key={option.transporterId} value={option.transporterId}>
                {option.transporter_name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
          )}
        </Select>
      </FormControl>

      <Link sx={{ width: 200, display: 'flex', alignItems: 'center' }} href="#" underline="none" onClick={manageAddTransporterTab}>
        Add Transporter
      </Link>
    </Stack>
          
          <Stack direction={"row"} spacing={2} padding={2}>
         
              <strong>Billing Address</strong>
          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Pincode" required value={form?.billingPincode || ""} type="text" fullWidth 
           
           onChange={async(e) => {
            const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 6);
            
            if (sanitizedValue.length <= 7) {
              if (sanitizedValue.length === 6) {
        console.log("logg", sanitizedValue);
        await fetchPinCode1(sanitizedValue);
      }
       changeHandler("billingPincode", sanitizedValue);
    }
  
          }}
          error={errors.billingPincode}
           />
          <TextField label="State" required InputLabelProps={{
            shrink: true,
          }} disabled value={form?.billingState}  type="text" fullWidth />
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="District" required InputLabelProps={{
              shrink: true,
            }} disabled value={form?.billingCity} type="text" fullWidth />

<TextField label="Address Line 1" value={form?.billingAddressLine1} required type="text" fullWidth onChange={(e)=>changeHandler("billingAddressLine1",e.target.value)} error={errors.billingAddressLine1}/>

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Address Line 2" value={form?.billingAddressLine2} type="text" fullWidth onChange={(e)=>changeHandler("billingAddressLine2",e.target.value)}/>
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
<strong>Shipping Address</strong>
</Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Pincode" value={form?.shippingPincode || ""} required type="text" fullWidth 
           
           onChange={async(e) => {
            const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 6);
            
            if (sanitizedValue.length <= 7) {
              if (sanitizedValue.length === 6) {
        console.log("logg", sanitizedValue);
        await fetchPinCode2(sanitizedValue);
      }
       changeHandler("shippingPincode", sanitizedValue);
    }
  
          }}
          error={errors.shippingPincode}
           />
          <TextField label="State"  InputLabelProps={{
            shrink: true,
          }} disabled required value={form?.shippingState} type="text" fullWidth />
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="District" InputLabelProps={{
              shrink: true,
            }} disabled required value={form?.shippingCity} type="text" fullWidth />

<TextField label="Address Line 1" required value={form?.shippingAddressLine1} type="text" fullWidth onChange={(e)=>changeHandler("shippingAddressLine1",e.target.value)} error={errors.shippingAddressLine1}/>

          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Address Line 2" value={form?.shippingAddressLine2} type="text" fullWidth onChange={(e)=>changeHandler("shippingAddressLine2",e.target.value)}/>
          </Stack>


          {/* </Box> */}
        {/* </DataGridContainer> */}
    
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding:"5px" }}>
       {status? <Button variant="contained"  onClick={()=>onsubmit(form)} >

    Create
 </Button>:<Button variant="contained" onClick={()=>onEdit(form)} >

Update
</Button>}
 </div> */}
      </>
    );
  };

  const fetchEmployees=async()=>{
    try{
      setLoading(true)
    const results = await window.Platform.database.getUsersForWork();
    setEmployee(results.data)
    setAssignedEmp(null)
    if(!status){
     const filteredEmp=results?.data?.filter((data)=>data.id===dataProp.ownerId)
     console.log("filteredEmp",filteredEmp);
     setAssignedEmp(filteredEmp[0])
    }
    setLoading(false)

  }
  catch (e) {
    setLoading(false)

  console.log(e)
  }
 
   }
 

const onEdit=async(data)=>{
  try{
    if(!form?.distributorName||!form?.pincode||!form?.state||!form?.district
      ||!form?.addressLine1||!form?.gstNo||!form?.kyc||!form?.bankName||!form?.accountNo
      ||!form?.holderName||!form?.ifsc||!form?.branchName||!form?.billingPincode
      ||!form?.billingState||!form?.billingCity||!form?.billingAddressLine1||!form?.shippingPincode
      ||!form?.shippingState||!form?.shippingCity||!form?.shippingAddressLine1||!form?.ownerId)
      {
        window.NotificationUtils.showWarning("Please fill all required fields to proceed");

        return;
      }
    setLoading(true)
    console.log("FINALDATA",form)
   const results = await window.Platform.database.editDistributor({...data,id:dataProp.distributorID});
    
    window.NotificationUtils.showSuccess("Distributer updated successfully!")
    setLoading(false)
    onClose()
    fetchData()

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
}

 
  const onsubmit=async(data)=>{
    try{
      // if(!form?.distributorName||!form?.pincode||!form?.state||!form?.district
      //   ||!form?.addressLine1||!form?.gstNo||!form?.kyc||!form?.bankName||!form?.accountNo
      //   ||!form?.holderName||!form?.ifsc||!form?.branchName||!form?.billingPincode
      //   ||!form?.billingState||!form?.billingCity||!form?.billingAddressLine1||!form?.shippingPincode
      //   ||!form?.shippingState||!form?.shippingCity||!form?.shippingAddressLine1||!form?.ownerId)
      //   {
      //     window.NotificationUtils.showWarning("Please fill all required fields to proceed");

      //     return;
      //   }

      const errors = validateForm(form);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true)
        //  const results = await window.Platform.database.addDistributor(data);
        window.NotificationUtils.showSuccess("Distributer added successfully!")
        setLoading(false)
        onClose1(data)
        // fetchData()
      } else {
        window.NotificationUtils.showWarning("Please fill all required fields to proceed");
        return;
      }

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }

      const fetchPinCode=async(details, type = null)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.fetchPinCode(details);
      console.log("pincode: " ,results.data[0].StateName)
          if (type === "transporter") {
            setTransporterForm({ ...transporterForm, pincode: details, state: results.data[0].StateName });
          } else {
            setForm({...form,state:results.data[0].StateName,district:results.data[0].District});
          }
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }

      const fetchPinCode1=async(details)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.fetchPinCode(details);
      console.log("pincode: " ,results.data[0].StateName)
       setForm({...form,billingState:results.data[0].StateName,billingCity:results.data[0].District,shippingState:results.data[0].StateName})
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }

      const fetchPinCode2=async(details)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.fetchPinCode(details);
      console.log("pincode: " ,results.data[0].StateName)
       setForm({...form,shippingCity:results.data[0].District})
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }

  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
       { status? "Add Distributor":"Edit Distributor"}
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Distributor Details" disabled={showTransporterForm} />
              <Tab label="Address Details" disabled={showTransporterForm} />
              <Tab label="Upload Images" disabled={showTransporterForm} />
              <Tab label="Add Transporter" style={{ display: showTransporterForm ? 'block' : 'none' }} />
           
            </StyledTabs>
              { tabValue === 0 && renderContent1() }
              { tabValue === 1 && renderContent() }
              { tabValue === 2 && renderContent2() }
              { showTransporterForm && renderTransporterForm() }
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default DistributorModal;