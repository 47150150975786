import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';

import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

import "./orderLineItemModal.css";
import LogoMittsure from '../Logo1.png';

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const LeadRepresentModal = ({ onCancel ,data, props }) => {
    const [dataSet, setDataSet] = useState({});
    const [filteredData, setFilteredData] = useState({});




    

const getColumns = () => {
  let result = [
    {
        field: "status",
        headerName: "Status",
        sortable: false,
        width: 250,
        
        editable: false,
        renderCell: (params) => {
          let name = params.row?.status || params.row?.status || "N/A";
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "reason",
        headerName: "Reason",
        sortable: false,
        width: 250,
        
        editable: false,
        renderCell: (params) => {
          let name = params.row?.reason || params.row?.reason || "N/A";
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    {
      field: "itemName",
      headerName: "Lead Name",
      sortable: false,
      width: 250,
      
      editable: false,
      renderCell: (params) => {
        let name = params.row?.name || params.row?.name || "N/A";

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "QTY",
      headerName: "Source Lead",
      width: 120,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.sourceLead||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "Price",
        headerName: "Rating Lead",
        width: 120,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.ratingLead||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Total",
        headerName: "Status Lead",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.statusLead||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
     

 
  ];


  return result
}

const getRowClassName = (params) => {
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

const renderContent = () => {
  return (
      <>
        <DataGrid
          className="payrollGrid"
          sx={{ height: isMobile ? "555px" : "500px"}}
          density="compact"
          rows={data}
          columns={getColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}

          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
        />
        {/* <Button variant="contained" color="primary"
          onClick={()=> downloadInvoice()} sx={{ mt: 2, borderRadius: '10px' }}>
          <DownloadIcon sx={{ mr: 1 }}  />
          Download Order Form
        </Button> */}
      </>
  );
};
    
    // console.log("customerdata", loading)
  
  
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px",minWidth:isMobile?350:750,width:isMobile?350:750
      },
    }}>
    <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Lead Add Report {/*- <Tooltip title="Order Id">{"("+ data.id+")"}</Tooltip>*/}</Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default LeadRepresentModal;
  