import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const AddLeadModal = ({ props,onClose,remarks,data,id,distributorId,fetchData,selectedTab,setView,onOpenRepresent}) => {
  console.log("DATA",data);
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  const [loading,setLoading]=useState(false)

 const [form,setForm]=useState({})
 const [form1,setForm1]=useState({})
 const [errors, setErrors] = useState({});
 const [subErrors, setSubErrors] = useState({});



const [dataGrid,setDataGrid]=useState([])

const [employee,setEmployee]=useState([])
const [assignedEmp,setAssignedEmp]=useState(null)

const [leadSource, setLeadSource] = useState([]);
const [assignedSource, setAssignedSource] = useState(null);
const [leadRating, setLeadRating] = useState([]);
const [assignedRating, setAssignedRating] = useState(null);
const [leadStatus, setLeadStatus] = useState([]);
const [assignedStatus, setAssignedStatus] = useState(null);

  const [board,setBoard]=useState()
 const [medium,setMedium]=useState()









  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  console.log("FORMM",form)
  
 
useEffect(()=>{
   
    fetchEmployees();
    fetchLeadSource();
    fetchLeadRating();
    fetchLeadStatus();
    getBoard();
    getMedium();

console.log("currentTab", tabValue)
},[])

  const getBoard = async () => {
    let results = await window.Platform.database.getBoard();
    setBoard(results.data);
  }

  const getMedium = async () => {
    let results = await window.Platform.database.getMedium();
    const finalData = results?.data?.filter(medium => medium.mediumTableId != "buCiAvMirI");
    setMedium(finalData);
  }

const fetchEmployees=async(data)=>{
    setLoading(true)
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
    setAssignedEmp(null)
    
    setLoading(false)

   }

  const fetchLeadSource = async data => {
    setLoading(true);
    const results = await window.Platform.database.fetchLeadSource(data);
    setLeadSource(results.data);
    setAssignedSource(null);
    setLoading(false);
  }

  const fetchLeadRating = async data => {
    setLoading(true);
    const results = await window.Platform.database.fetchLeadRating(data);
    setLeadRating(results.data);
    setAssignedRating(null);
    setLoading(false);
  }

  const fetchLeadStatus = async data => {
    setLoading(true);
    const results = await window.Platform.database.fetchLeadStatus(data);
    setLeadStatus(results.data);
    setAssignedStatus(null);
    setLoading(false);
  }
 
const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

const renderContent1=()=>{
  return (
    <div style={{maxHeight:"510px",height:"505px",overflowY:"auto"}}>
   
  <Stack direction={"row"} spacing={2} padding={2}>
 </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Lead Name" value={form?.name || ""} type="text"fullWidth onChange={(e)=>changeHandler("name",e.target.value)}/>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value.id)
                setAssignedEmp(value)

                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                required
                variant="outlined"
                error={errors.ownerId ? true : false}
              />
            )}/>
            
</Stack> 

      <Stack direction={"row"} spacing={2} padding={2}>
        <Autocomplete
          options={leadSource}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => option?.name || ''}
          style={{ width: "100%" }}
          value={assignedSource}
          noOptionsText={loading ? "Loading..." : "No option"}
          onChange={(event, value) => {
            changeHandler("sourceLead", value.name)
            setAssignedSource(value)
          }}

          onInputChange={(e, value) => {
            const matchingOption = employee.find((option) => option.name === value.name);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Lead Source"}
              variant="outlined"
              required
              error={subErrors.sourceLead ? true : false}
            />
          )} />

        <Autocomplete
          options={leadRating}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => option?.name || ''}
          style={{ width: "100%" }}
          value={assignedRating}
          noOptionsText={loading ? "Loading..." : "No option"}
          onChange={(event, value) => {
            changeHandler("ratingLead", value.name)
            setAssignedRating(value)
          }}
          onInputChange={(e, value) => {
            const matchingOption = employee.find((option) => option.name === value.name);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Lead Rating"}
              variant="outlined"
              required
              error={subErrors.ratingLead ? true : false}
            />
          )} />

        <Autocomplete
          options={leadStatus}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => option?.name || ''}
          style={{ width: "100%" }}
          value={assignedStatus}
          noOptionsText={loading ? "Loading..." : "No option"}
          onChange={(event, value) => {
            changeHandler("statusLead", value.name)
            setAssignedStatus(value)
          }}
          onInputChange={(e, value) => {
            const matchingOption = employee.find((option) => option.name === value.name);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Lead Status"}
              variant="outlined"
              required
              error={subErrors.statusLead ? true : false}
            />
          )} />
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <TextField
          label="Address"
          value={form?.address || ""}
          type="text"
          fullWidth
          onChange={(e)=>changeHandler("address", e.target.value)}
        />

        <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "30%" }}>
          <InputLabel >School Board</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            value={form?.board || ""}
            onChange={(e) => changeHandler('board', e.target.value)}
          >
            {board?.map((option, optionIndex) => (
              <MenuItem key={option.boardId} value={option.boardId}>
                {option.boardName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "40%" }}>
          <InputLabel id={`select-label`}>Medium</InputLabel>
          <Select
            labelId={`Product`}
            label="Medium"
            value={form?.medium || ""}
            onChange={(e) => changeHandler('medium', e.target.value)}
          >
            {medium?.map((option, optionIndex) => (
              <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
                {option.mediumName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <TextField
          label="Contact Person Name"
          value={form?.contactPersonName || ""}
          type="text"fullWidth
          onChange={(e)=>changeHandler("contactPersonName", e.target.value)}
        />

        <TextField
          label="District"
          value={form?.district || ""}
          type="text"fullWidth
          onChange={(e)=>changeHandler("district", e.target.value)}
        />

        <TextField
          label="State"
          value={form?.state || ""}
          type="text"fullWidth
          onChange={(e)=>changeHandler("state", e.target.value)}
        />
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <TextField
          label="Email"
          value={form?.email || ""}
          type="text"fullWidth
          onChange={(e)=>changeHandler("email", e.target.value)}
        />

        <TextField
          label="Phone"
          value={form?.phone || ""}
          type="text"fullWidth
          onChange={(e)=>changeHandler("phone", e.target.value)}
        />

        <Button variant="text" onClick={() => handleAdd(form)}>
          Add
        </Button>
      </Stack>

 <Stack direction={"row"} spacing={2} padding={2}>

       
<DataGrid
className="payrollGrid"
//   checkboxSelection={true}
sx={{height:isMobile ? "555px":"310px"}}
density="compact"
// checkboxSelection={true}
rows={dataGrid||[]}
columns={getColumns()}
rowHeight={60}
// onRowSelectionModelChange={handleSelectionModelChange}
disableSelectionOnClick
disableRowSelectionOnClick
initialState={{
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
}}
pageSizeOptions={[10]}
// autoPageSize
// components={{
//   Toolbar: CustomToolbar,
// }}
/>
    

       
             
         
    </Stack> 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

 <Button variant="contained" onClick={()=>onsubmit(form)} >
    Create
 </Button>
 </div>
      
</div>
)}



  const handleDeleteEmploye = async (id) => {
    // const response = await window.Platform.database.deleteUsers(ID);

    let res = dataGrid.filter((el) => {
      return el.id != id;
    });

    setDataGrid(res);
  };

  const getColumns = () => {
    let result = [
     
      {
        field: "nameSku",
        headerName: "Lead Name",
        width: 170,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.name||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //     field: "Price",
      //     headerName: "User ID",
      //     width: 150,
      //     // height: 10,
      //     // headerAlign: 'center',
      //     sortable: false,
      //     // editable: true,
      //     renderCell: (params) => {
    
      //       let name =params.row.ownerId||"N/A"
    
      //       return (
    
      //         <Tooltip title={name || "N/A"}>
    
      //           <Typography variant="inherit">{name}</Typography>
    
      //         </Tooltip>
    
      //       )
    
      //     },
      //   },
      {
        field: "leadSource",
        headerName: "Lead Source",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let leadSource = params.row.sourceLead || "N/A"
          return (
            <Tooltip title={leadSource || "N/A"}>
              <Typography variant="inherit">{leadSource}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "leadRating",
        headerName: "Lead Rating",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let leadRating = params.row.ratingLead || "N/A"
          return (
            <Tooltip title={leadRating || "N/A"}>
              <Typography variant="inherit">{leadRating}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "leadStatus",
        headerName: "Lead Status",
        width: 170,
        sortable: false,
        renderCell: (params) => {
          let leadStatus = params.row.statusLead || "N/A"
          return (
            <Tooltip title={leadStatus || "N/A"}>
              <Typography variant="inherit">{leadStatus}</Typography>
            </Tooltip>
          )
        },
      },

      {
        field: "address",
        headerName: "Address",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.address || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.address || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "board",
        headerName: "Board",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.board || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.board || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "contactPersonName",
        headerName: "ContactPersonName",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.contactPersonName || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.contactPersonName || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "district",
        headerName: "District",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.district || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.district || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.email || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.email || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "medium",
        headerName: "Medium",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.medium || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.medium || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "state",
        headerName: "State",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.state || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.state || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.phone || 'N/A');
        },
        renderCell: (params) => {
          let name = params.row?.phone || 'N/A';
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
       
          {
            field: "Actions",
            headerName: "Actions",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.total||"N/A"
      
              return (
      
               <IconButton onClick={()=>handleDeleteEmploye(params.row.id)}>
<DeleteIcon/>
               </IconButton>
      
              )
      
            },
          },
  
       
  
   
    ];
  
  
    return result
  }
  console.log("FORMMM!",form1)

 

  const validateForm = (data) => {
    const errors = {};
 
    if (!dataGrid.length) {
      if (!data.ownerId?.trim()) {
        errors.ownerId = "Assign to is required";
      }
    }
      
    return errors;
  };

  const validateSubForm = (data) => {
    const subErrors = {};
 
    if (!data.sourceLead?.trim()) {
      subErrors.sourceLead = "Lead source is required";
    }

    if (!data.ratingLead?.trim()) {
      subErrors.ratingLead = "Lead rating is required";
    }

    if (!data.statusLead?.trim()) {
      subErrors.statusLead = "Lead status is required";
    }
      
    return subErrors;
  };
 
 
  console.log("FINALDATA",dataGrid)
  const onsubmit=async(data)=>{
    try{
      const errors = validateForm(dataGrid);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true)

        const results = await window.Platform.database.addLeadAdmin({data: dataGrid});
        setView(results.data)
console.log("SUCCESS",results.data)

        // window.NotificationUtils.showSuccess("Lead added successfully!")
        setLoading(false)
        onClose()
        onOpenRepresent()
        fetchData()
      } else {
        window.NotificationUtils.showError("Please fill all required fields.")
        setLoading(false)
      }

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }

const handleAdd=(data)=>{

    console.log("DATAAADD",data)
    if(data.name && data.ownerId){
      const errors = validateSubForm(data);
      setSubErrors(errors);

      if(Object.keys(errors).length === 0) {
        let pusheItem={...data,id:dataGrid.length+1}

        setDataGrid([...dataGrid,pusheItem])
        setAssignedEmp(null)
        setForm(null)
        setAssignedRating(null);
        setAssignedSource(null);
        setAssignedStatus(null);
      } else {
        window.NotificationUtils.showWarning("Please Fill All Data")
      }
    }
    else{
    window.NotificationUtils.showWarning("Please Fill All Data")

    } 


}

  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}  >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         Add Lead
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Lead Details " />
            
           
            </StyledTabs>
            {tabValue==0&&renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default AddLeadModal;