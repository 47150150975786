import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CssBaseline from '@mui/material/CssBaseline';
import { MONTHS, ROLES } from "../../constants";
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import { AddCircle, ChargingStationRounded, Email, ManageAccountsOutlined, ManageHistory, ManageSearch, ManageSearchTwoTone, Sell, TrackChanges, Warehouse } from "@mui/icons-material";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import Drawer from '../../components/Drawer';
import Header from "../../components/common/drawer/drawer";
import CommomHeader from '../../components/common/commonHeader/commonHeader';
import { Autocomplete, Avatar, Button, Grid, Paper, Stack, TextField } from "@mui/material";
import { Container } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { DataGrid } from "@mui/x-data-grid";
import employeeIcon from "./ICONS/employee.svg"
import salesIcon from "./ICONS/sales.svg"
import coursesIcon from "./ICONS/courses.svg"
import distributorIcon from "./ICONS/distributor.svg"
import erpIcon from "./ICONS/erp.svg"
import SchoolIcon from "./ICONS/school.svg"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import moment from "moment";
import dayjs from "dayjs";




// import Header from "../../components/header";

const isMobile = window.innerWidth < 900;



const ShortcutCard = styled(Card)`
  width: 250px;
  height: 200px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 0px;
  &:hover {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`

const StyledCardContent = styled(CardContent)`
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardHeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`

const CardIcon = styled(ManageAccounts)`
  font-size: 112px;
  color: #21225F;
`;

const ContentContainer = styled.div`
padding: 20px;
// position: ${isMobile ? "relative" : "fixed"};
top: 64px;
left: ${isMobile ? `0px` : `240px`};
width: ${isMobile ? `100%` : `calc(100% - 24px)`};
${!isMobile && `max-height: calc(100% - 64px);`}
overflow-y: auto;
display:flex;
flex-direction: ${isMobile ? 'column' : 'row'};
gap:20px;
${isMobile && `align-items:center;`}
flex-wrap:wrap;
`

const DurationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  gap: 5px;

  & > button {
    font-size: 12px;
    height: 30px;
    margin-bottom: 3px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background-color: ${(props) => (props.isselected ? 'black' : 'white')};
    color: ${(props) => (props.isselected ? 'white' : 'rgb(70, 70, 255)')};
    padding: 2px 7px;
    border-radius: 3px;
    border: 1px solid rgb(70, 70, 255);
    cursor: pointer;
  }
`;


const Dashboard = () => {
  const[loading, setLoading] =useState(false)



  const [totalEmployees, setTotalEmployees] = useState(0)
  const [totalSchool, setTotalSchool]= useState(0)
  const [totalDistributors, setTotalDistributors] = useState(0)
  const [ salesRevenue, setSalesRevenue] = useState(0)
  const [ erpSchool, setErpSchool ] = useState(0)
  const [totalCourses, setTotalCourses] = useState(10)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [totalEarning, setTotalEarning] = useState([])
  const fetchDashboardTotalEarningMonthWise = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardTotalEarningMonthWise(startDate, endDate);
    
      setTotalEarning(results.data)
  console.log("dashboard total Earning",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }
  const [schoolEarning, setSchoolEarning] = useState([])
  const fetchDashboardTotalEarningSchoolMonthWise  = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardTotalEarningSchoolMonthWise(startDate, endDate);
    
      setSchoolEarning(results.data)
  console.log("dashboard School Earning",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }
  

  const [topEmployees, setTopEmployees] = useState([])
  const fetchDashboardEmployeeWithRevenue = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardEmployeeWithRevenue(startDate, endDate);
    
      setTopEmployees(results.data)
  console.log("dashboard emplouees top",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }

  const[ schoolListed, setSchoolListed] = useState([]);
  const fetchDashboardSchoolOnboardingList = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardSchoolOnboardingList(startDate, endDate);
    
      setSchoolListed(results.data)
  console.log("dashboard school",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }

  const [topProductsData, setTopProductsData] = useState([])
const [dealerRow, setDealerRow] =useState([])
const fetchDashboardDistributorOnboardingList = async() => {
  try {
    let results = await window.Platform.database.fetchDashboardDistributorOnboardingList(startDate, endDate);
  
    setDealerRow(results.data)
console.log("dashboard dealer",results.data)
  } catch (error) {
    console.error(error);
    // window.NotificationUtils.showError("Error fetching Notifications");
  }
}

  const fetchTotalEmployees = async() => {
    try {
      setLoading(true)
    let results = await window.Platform.database.fetchDashboardUsers();
      let resultsSchool = await window.Platform.database.fetchDashboardSchool();
      let resultstotalDistributors = await window.Platform.database.fetchDashboardDistributor();
      let resultssalesRevenue = await window.Platform.database.fetchDashboardTotalRevenueOrder();
      let resultserpSchool = await window.Platform.database.fetchDashboardSchoolErpOnboard();
      let resultstotalCourses = await window.Platform.database.fetchDashboardSchool();


console.log("dashboard Users",results.data)
setTotalEmployees(results?.data||0)
setTotalSchool(resultsSchool.data||0)
setTotalDistributors(resultstotalDistributors.data||0)
setSalesRevenue(resultssalesRevenue.data||0)
setErpSchool(resultserpSchool.data||0)
setLoading(false)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
      setLoading(false)
    }
  }

  const fetchTopProducts = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardProductRank(startDate, endDate);
    

console.log("dashboard Products",results.data)
setTopProductsData(results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }
const [dashboardVisitTable, setDashboardVisitTable] = useState([])
  const fetchDashboardVisitVsOrderUser = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardVisitVsOrderUser(startDate, endDate);
    
setDashboardVisitTable(results.data)
console.log("dashboard visits",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }

  const [specimenData, setSpecimenData] = useState([])
  const [salesData, setSalesData] = useState([])


  useEffect(()=> {
    console.log("date here",startDate, endDate)
  },[startDate, endDate])
  const fetchDashboardOrderVsSpecimen = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardOrderVsSpecimen(startDate, endDate, schoolSelectSpecimenVsSales, employeeSelectSpecimenVsSales);
    
      // setSpecimenData(results.sales)
      setSalesData(results.data)
console.log("dashboard specimenvssales",results?.sales?.salesQuantity)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }

  const [distributorEarning, setDistributorEarning] = useState([])
  const fetchDashboardTotalEarningDistributorMonthWise = async() => {
    try {
      let results = await window.Platform.database.fetchDashboardTotalEarningDistributorMonthWise(startDate, endDate);
    
      setDistributorEarning(results.data)
      
console.log("dashboard specimenvssales",results.data)
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  }
  

  useEffect(()=> {
    fetchTotalEmployees();
    fetchTopProducts()
    fetchDashboardVisitVsOrderUser()
    fetchDashboardDistributorOnboardingList()
    fetchDashboardSchoolOnboardingList()
    fetchDashboardEmployeeWithRevenue()
    fetchDashboardTotalEarningMonthWise()
    fetchDashboardTotalEarningSchoolMonthWise()
    fetchDashboardOrderVsSpecimen()
    fetchDashboardTotalEarningDistributorMonthWise()
  },[endDate])

  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    console.log("dash", auth);
  }, [auth]);

  const cardData = [
    {
      title: 'Role Management',
      icon: ManageSearch,
      link: '/rolemanagement',
      role: ['admin', 'Super Admin'],
    },
    {
      title: 'User Management',
      icon: ManageAccounts,
      link: '/userManagement',
      role: ['admin', 'Super Admin'],
    },
    // {
    //   title: 'Tracking',
    //   icon: TrackChanges,
    //   link: '/tracking',
    //   role: ['admin'],
    // },
    // {
    //   title: 'Quotation Generation',
    //   icon: ManageSearchTwoTone,
    //   link: '/quotationgeneration',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
    // {
    //   title: 'SO Generation',
    //   icon: ChargingStationRounded,
    //   link: '/sogeneration',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
    // {
    //   title: 'Billing',
    //   icon: Sell,
    //   link: '/billing',
    //   role: ['user', 'admin'],
    // },
    // {
    //   title: 'WH Management',
    //   icon: Warehouse,
    //   link: '/warehousemanagement',
    //   role: ['user', 'admin', 'Super Admin'],
    // },
  ];

  const renderCards = () => {
    console.log('render cards', auth.user.user);
    const filteredCards = cardData.filter((card) =>
      card.role.includes(auth?.user?.user?.role)
    );

    return filteredCards.map((card, index) => (
      <ShortcutCard key={index}>
        <NavLink to={card.link}>
          <StyledCardContent>
            <CardHeaderContainer>
              <StyledCardHeading variant="h6" align="center">
                {card.title}
              </StyledCardHeading>
            </CardHeaderContainer>
            <CardIcon as={card.icon} sx={{ fontSize: '112px' }} />
          </StyledCardContent>
        </NavLink>
      </ShortcutCard>
    ));
  };

  const MyCard = styled(Card)({
    marginBottom: 15,
    height: "80px"
  });

  const MyCardContent = styled(CardContent)({
    // Add your styles for CardContent if needed
    display: 'flex',
    gap: 80
  });

  const AvatarContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    // marginTop: 'px', // Adjust as needed
  });

  const NumberContainer = styled('div')({
    height: '25px',
    marginRight: '8px', // Adjust as needed
    textAlign: 'right', // Align to the right

  });

  const NumberTypography = styled(Typography)({
    height: '25px',
    marginRight: '8px', // Adjust as needed
    textAlign: 'right', // Align to the right

  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    height: 100,
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));

  const chartOptions = {
    labels: topProductsData?.map((val) => val.nameSku || ''),
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFD700', '#20B2AA', '#8A2BE2', '#FFA07A', '#008000'],
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
        dataLabels: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
  };
  
  const chartSeries = topProductsData?.map((val) => val.id_count || '');
  
  const columnChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%', // Adjust the width of the columns
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: salesData.map((val)=> MONTHS[val.month]), // All 12 months
    },
    colors: ['#008FFB', '#00E396'],
  };

  const columnChartSeries = [
    {
      name: 'Specimen',
      data: salesData.map((val)=> val?.specimenQuantity), // Sample data for Specimen
    },
    {
      name: 'Orders',
      data: salesData.map((val)=> val?.salesQuantity), // Sample data for Orders
    },
  ];

  const lineChartOptions1 = {
    chart: {
      type: 'line',
      height: 350,
    },
    xaxis: {
      categories: totalEarning.map((val)=> MONTHS[val.month]),
    },
    colors: ['#008FFB'],
  };

  const lineChartSeries1 = [
    {
      name: 'Data Series',
      data: totalEarning.map((val)=> {
        return Number.isInteger(val.total_sum) ? val.total_sum : val.total_sum.toFixed(2)
      }),
    },
  ];

  const lineChartOptionsSchool = {
    chart: {
      type: 'line',
      height: 350,
    },
    xaxis: {
      categories: schoolEarning.map((val)=> {
        return MONTHS[val.month]
      }),
    },
    colors: ['#008FFB'],
  };

  const lineChartSeriesSchool = [
    {
      name: 'Data Series',
      data: schoolEarning.map((val)=> {
        return Number.isInteger(val.total_sum) ? val.total_sum : val.total_sum?.toFixed(2)
      }),
    },
  ];


  const lineChartOptionsDealer = {
    chart: {
      type: 'line',
      height: 350,
    },
    xaxis: {
      categories: distributorEarning.map((val)=> {
        return MONTHS[val.month]
      }),
    },
    colors: ['#008FFB'],
  };

  const lineChartSeriesDealer = [
    {
      name: 'Data Series',
      data: distributorEarning.map((val)=> {
        return Number.isInteger(val.total_sum) ? val.total_sum : val.total_sum.toFixed(2)
      }),
    },
  ];

  const columnsVisit = [
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'mobno', headerName: 'Mobile No.', width: 200 },
    { field: 'visit_count', headerName: 'Visit Count', width: 150 },
    { field: 'order_count', headerName: 'Order Count', width: 120 },
    { field: 'overall_rank', headerName: 'Overall Rank', width: 150 },
  ];
  const dealerColumn = [

    { field: 'name', headerName: 'Name', width: 70 },
    { field: 'DistributorName', headerName: 'Distributor Name', width: 200 },
    { field: 'Pincode', headerName: 'Pincode', width: 120 },
    { field: 'State', headerName: 'State', width: 150 },
    { field: 'makerContact', headerName: 'Make Contact', width: 120 },
    // { field: 'overall_rank', headerName: 'Overall Rank', width: 150 },
  ];
  const schoolColumn = [

    // { field: 'schoolId', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'schoolName', headerName: 'School Name', width: 200 },
    { field: 'makerContact', headerName: 'Contact', width: 150 },
    { field: 'State', headerName: 'State', width: 120 },
    { field: 'Pincode', headerName: 'Pincode', width: 150 },
  ];

  const employeeColumn = [

    // { field: 'schoolId', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'email', headerName: 'Email', width: 120 },
    { field: 'ranking', headerName: 'Ranking', width: 90 },
    { field: 'mobno', headerName: 'Mobile', width: 120 },
    { field: 'manager', headerName: 'Manager', width: 150 },
  ];
  const getRowId = (row) => row.user_id; // Use a property that uniquely identifies each row
  const getRowIdDealer = (row) => row.distributorID; // Use a property that uniquely identifies each row
  const getRowIdSchool = (row) => row.schoolId; // Use a property that uniquely identifies each row
  const getRowIdEmployee = (row) => row.ownerId; // Use a property that uniquely identifies each row
  const [isselected, setIsSelected] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedbutton, setSelectedButton] = useState('1M'); // Set default value to '1M'
  const [minDate,setMinDate]=useState()

  const [schoolSpecimenVsSales, setSchoolSpecimenVsSales] = useState([]);
  const [schoolSpecimenVsSalesLabel, setSchoolSpecimenVsSalesLabel] = useState([]);

  const [schoolSelectSpecimenVsSales, setSchoolSelectSpecimenVsSales] = useState(null);



  const [employeeSpecimenVsSales, setEmployeeSpecimenVsSales] = useState([]);
  const [employeeSpecimenVsSalesLabel, setEmployeeSpecimenVsSalesLabel] = useState([]);
  const [employee,setEmployee]=useState([])

  const [employeeSelectSpecimenVsSales, setEmployeeSelectSpecimenVsSales] = useState(null);


useEffect(()=> {
  fetchDashboardOrderVsSpecimen()
},[employeeSelectSpecimenVsSales, schoolSelectSpecimenVsSales])

  const fetchSchools = async() => {

    try
    {
      let schoolFetch = await window.Platform.database.getSchoolForDropDown();
      console.log("scchool", schoolFetch)
      setSchoolSpecimenVsSalesLabel(schoolFetch.school)
      setSchoolSpecimenVsSales(schoolFetch.school.map((data)=> ( {id: data.schoolId})))
    }
    catch(error)
    {
      console.error(error);
    }

  }

  useEffect(()=> {
    fetchSchools();
    fetchEmployees()
  },[])
  const fetchEmployees = async() => {

    try{
      let schoolFetch = await window.Platform.database.getUsersForWork();
      console.log("scchool", schoolFetch)
      setEmployeeSpecimenVsSalesLabel(schoolFetch.data)
      setEmployeeSpecimenVsSales(schoolFetch.data.map((data)=> ( {id: data.id})))
      setEmployee(schoolFetch.data)
        }
    catch(error){
      console.error(error);
    }

  }

  const handleDivClick = (buttonName) => {
    setIsSelected(!isselected); // Toggle the selection state
    setSelectedButton(buttonName);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      setStartDate(dayjs(date).format('YYYY/MM/DD'));
      setMinDate(date)
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    
    if (date) {
      setEndDate(dayjs(date).format('YYYY/MM/DD'));
    } else {
      setEndDate(null);
    }
  };

  // const formatDate=(data)=>{
  //   console.log("DATAAAA",data   )
  //   // setDate(data)
  //   const timestamp = new Date(data.$d);
   
  //   const datePickerResponse = new Date(data.$d);
  
  //   const year = datePickerResponse.getFullYear();
  // const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  // const day = String(datePickerResponse.getDate()).padStart(2, '0');
  // const formattedDate = `${year}-${month}-${day}`;
  // const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
  // // setDate(data)
  // setDob(data)
  
  // detailUpdate("dob",unixTimestamp)
  // console.log("UNIX",unixTimestamp);
  // }
  
  return (
    <div style={{ display: "flex" }}>


      {/* <ContentContainer> */}
{loading && <OpaqueLoading/>}

      <Container>

      <DurationContainer>
      {/* <textarea name="postContent" rows={1} cols={10} /> */}
      {/* <Button onClick={() => handleDivClick('1M')} isselected={selectedbutton === '1M' ? 'true' : 'false' } style={{backgroundColor: selectedbutton === '1M' ? "rgba(173, 216, 230, 1)": "white"}}>
        1M
      </Button>
      <Button onClick={() => handleDivClick('3M')} isselected={selectedbutton === '3M' ? 'true' : 'false'} style={{backgroundColor: selectedbutton === '3M' ? "rgba(173, 216, 230, 1)": "white"}}>
        3M
      </Button>
      <Button onClick={() => handleDivClick('6M')} isselected={selectedbutton === '6M' ? 'true' : 'false'} style={{backgroundColor: selectedbutton === '6M' ? "rgba(173, 216, 230, 1)": "white"}}>
        6M
      </Button> */}
       <Stack direction={"row"} spacing={2} padding={2}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date Filter"
          value={startDate}
          sx={{ width: '100%' }}
          format="YYYY/MM/DD"
          onChange={handleStartDateChange}
          disableFuture={true}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
          label="End Date Filter"
          value={endDate}
          
          minDate={minDate}
          sx={{ width: '100%' }}
          format="YYYY/MM/DD"
          onChange={handleEndDateChange}
          disableFuture={true}
        />
      </LocalizationProvider>
           
          </Stack>
    </DurationContainer>
        <Grid>

          <Grid container spacing={2} style={{ marginBottom: 15 }} >
            {/* First Row */}
            <Grid item xs={3}>
              {/* Card 1 */}
              <MyCard >
                <MyCardContent style={{gap: "30%"}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={employeeIcon} alt="icon" style={{ width: '80%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(totalEmployees) ? totalEmployees : totalEmployees.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">Total Employees</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
             <MyCard>
              
                <MyCardContent style={{gap: "35%"}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={salesIcon} alt="icon" style={{ width: '90%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(salesRevenue) ? salesRevenue : salesRevenue.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">Sales Revenue</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
              <Card style={{  width: "312%", height:
                  // "66.5%"
                  "389px" }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <div style={{display: "flex", gap: 20}}>

        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Specimen vs Sales Order
        </Typography>
        <Autocomplete
        options={schoolSpecimenVsSales}
        style={{ width: '60%' }}
        value={schoolSelectSpecimenVsSales} // Set to null initially
        autoSelect
        autoFocus
        getOptionLabel={(option) => {
          let manager = schoolSpecimenVsSalesLabel.find(
            (member) => member.schoolId === option.id
          );
          return `${manager?.schoolName || ''} `;
        }}
        onChange={(e, value) => {
          setSchoolSelectSpecimenVsSales(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Schools'}
            required
            variant="outlined"
          />
        )}
      />

      <Autocomplete
        options={employee}
        style={{ width: '60%' }}
        value={employeeSelectSpecimenVsSales} // Set to null initially
        autoSelect
        autoFocus
        // getOptionLabel={(option) => {
        //   let manager = employeeSpecimenVsSalesLabel.find(
        //     (member) => member.id === option.id
        //   );
        //   return `${manager?.name || ''} (${manager?.role || ''})`;
        // }}
        // getOptionValue={(option) => {
        //   let manager = employeeSpecimenVsSalesLabel.find(
        //     (member) => member.id === option.id
        //   );
        //   return `${manager?.name || ''} (${manager?.role || ''})`;
        // }}
        getOptionLabel={(option) => `${option?.name} (${option?.role})`}
              getOptionValue={(option) => option?.id || ''}
        onChange={(e, value) => {
          setEmployeeSelectSpecimenVsSales(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Employees'}
            required
            variant="outlined"
          />
        )}
      />
        </div>

        {/* ApexCharts Column Chart */}
        <ReactApexChart type="bar" height={300} width={800} options={columnChartOptions} series={columnChartSeries} />
      </CardContent>
    </Card>
            </Grid>
            <Grid item xs={3}>
              {/* Card 1 */}
              <MyCard>
                <MyCardContent style={{gap: '40%'}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={SchoolIcon} alt="icon" style={{ width: '90%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(totalSchool) ? totalSchool : totalSchool.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">Total Schools</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
              <MyCard>
                <MyCardContent style={{gap: 65}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={erpIcon} alt="icon" style={{ width: '90%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(erpSchool) ? erpSchool : erpSchool.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">ERP User Schools</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
            </Grid>
            <Grid item xs={3}>
              {/* Card 2 */}
              <MyCard>
                <MyCardContent style={{gap: "29%"}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={distributorIcon} alt="icon" style={{ width: '90%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(totalDistributors) ? totalDistributors : totalDistributors.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">Total Distributors</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
              <MyCard>
                <MyCardContent style={{gap: "39%"}}>
                  {/* Left side with Circle Icon */}
                  <AvatarContainer>
                    {/* <Avatar> */}
                      {/* Add your circle icon here */}
                      <img src={coursesIcon} alt="icon" style={{ width: '90%', height: '100%', borderRadius: '50%' }} />
                    {/* </Avatar> */}
                    {/* Card Title */}
                    {/* <Typography variant="h6">Card 1</Typography> */}
                  </AvatarContainer>

                  {/* Right side with Number and Text */}

                  <NumberContainer>
                    {/* Number */}
                    <NumberTypography variant="h6" >{Number.isInteger(totalCourses) ? totalCourses : totalCourses.toFixed(2)}</NumberTypography>

                    {/* Text */}
                    <Typography variant="body2">Total Courses</Typography>
                  </NumberContainer>
                </MyCardContent>
              </MyCard>
            </Grid>

            <Grid item xs={3}>
              {/* Long Card spanning 2 rows */}
              <Card style={{ height: '560px' }}>
      <CardContent>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Top 10 Products
        </Typography>


        {/* ApexCharts Pie Chart */}
        <ReactApexChart type="donut" height={240} options={chartOptions} series={chartSeries} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px' }}>
          {chartOptions.labels.map((label, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: chartOptions.colors[index],
                    marginRight: '8px',
                  }}
                ></div>
                <Typography variant="body2" title={label}>{ label.length > 25 ? label.slice(0, 20) + '...' : label }</Typography>
              </div>
              <Typography variant="body2">{chartSeries[index]}</Typography>
            </div>
          ))}
        </div>
</CardContent>
</Card>

            </Grid>

          </Grid>

          {/* Second Row */}
          <Grid container spacing={2}>
            {/* First row */}
            <Grid item xs={6}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Total Earnings
        </Typography>


        {/* ApexCharts Line Chart */}
        <ReactApexChart type="line" height={350}  width={500} options={lineChartOptions1} series={lineChartSeries1} />
      </CardContent>
    </Card>
            </Grid>
            <Grid item xs={6}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
        {/* Title */}
        <Typography variant="h5" gutterottom style={{ textAlign: 'left' }}>
          School Earnings
        </Typography>

        {/* ApexCharts Line Chart */}
        <ReactApexChart type="line" height={350}  width={500} options={lineChartOptionsSchool} series={lineChartSeriesSchool} />
      </CardContent>
    </Card>            </Grid>

            {/* Second row */}
            <Grid item xs={6} style={{ marginBottom: 15 }}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Dealer Earnings
        </Typography>

        {/* ApexCharts Line Chart */}
        <ReactApexChart type="line" height={350}  width={500} options={lineChartOptionsDealer} series={lineChartSeriesDealer} />
      </CardContent>
    </Card>            </Grid>
            <Grid item xs={6}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Total Visit
        </Typography>

        {/* Material-UI DataGrid */}
        <div style={{ height: 340, width: '100%', marginBottom: '20px' }}>
        <DataGrid rows={dashboardVisitTable}         getRowId={getRowId}
 columns={columnsVisit} pageSize={10} rowHeight={40} hideFooterPagination hideFooter />
        </div>
      </CardContent>
    </Card>            </Grid>

            {/* Second row */}
            <Grid item xs={6} style={{ marginBottom: 15 }}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          School Listed
        </Typography>

        {/* Material-UI DataGrid */}
        <div style={{ height: 340, width: '100%', marginBottom: '20px' }}>
        <DataGrid rows={schoolListed} columns={schoolColumn} getRowId={getRowIdSchool} pageSize={10} rowHeight={40} hideFooterPagination hideFooter />
        </div>
      </CardContent>
    </Card>   
                </Grid>
            <Grid item xs={6}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Dealer Listed
        </Typography>

        {/* Material-UI DataGrid */}
        <div style={{ height: 340, width: '100%', marginBottom: '20px' }}>
        <DataGrid rows={dealerRow} columns={dealerColumn}  getRowId={getRowIdDealer} pageSize={10} rowHeight={40} hideFooterPagination hideFooter />
        </div>
      </CardContent>
    </Card>   
                </Grid>

            <Grid item xs={12} style={{ paddingBottom: 15 }}>
            <Card style={{ height: '400px' }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Title */}
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
          Top Employees
        </Typography>

        {/* Material-UI DataGrid */}
        <div style={{ height: 340, width: '100%', marginBottom: '20px' }}>
        <DataGrid rows={topEmployees} columns={employeeColumn} getRowId={getRowIdEmployee} pageSize={10} rowHeight={40} hideFooterPagination hideFooter />
        </div>
      </CardContent>
    </Card>               </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* </ContentContainer> */}
    </div>
  );
};

export default Dashboard;
