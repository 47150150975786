import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
  Chip
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const AddRoutePlan = ({ props,onClose,remarks,dataProp,id,distributorId,fetchData,status}) => {
  console.log("DATA0PROPA",dataProp);
  const [tabValue, setTabValue] = useState(0);

  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState([])

 const [form,setForm]=useState({
 })

 const [errors, setErrors] = useState({});
 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState()
 const [party,setParty]=useState(null)
 const [newParty,setNewParty]=useState([])
 const [newParty1,setNewParty1]=useState([])
 const [selectParty,setSelectParty]=useState(null)
 const [party1,setParty1]=useState(null)
 const [selectParty1,setSelectParty1]=useState(null)


 const [date,setDate]=useState()
 



 const [selectedProduct,setSelectedProduct]=useState(null)
 const [dataGrid,setDataGrid]=useState([])
 const [visitType,setVisitType]=useState([])

 const today = dayjs();
 const tomorrow = today.add(1, 'day');
 const dayAfterTomorrow = today.add(7, 'day');

  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  const formatDate=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate(data)


changeHandler("date",unixTimestamp)
console.log("UNIX",unixTimestamp);
}
  console.log("FORMM",form)
 
useEffect(()=>{
// if(tabValue===1){
//   fetchProduct(plantCode,distributorId)
// }
fetchEmployees()
// getSchool()
// getPartyLead()

console.log("currentTab", tabValue)
},[])

useEffect(()=>{
    // if(tabValue===1){
    //   fetchProduct(plantCode,distributorId)
    // }
    getSchool({ownerId:form.ownerId})
    getPartyLead({ownerId:form.ownerId})
    getRouteVisitType()
    
    console.log("currentTab", tabValue)
    },[form.ownerId])
 




const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const getSchool=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.getSchool(details);
//   console.log("pincode: " ,results.data[0].StateName)
setParty(results.data)
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }

  const getPartyLead=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.getPartyLead(details);
//   console.log("pincode: " ,results.data[0].StateName)
setParty1(results.data)
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }

  const getRouteVisitType=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.getRouteVisitType(details);
//   console.log("pincode: " ,results.data[0].StateName)
setVisitType(results.data)
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }


const renderContent1=()=>{
  return (
    <div style={{ overflowY:"auto" }}>
   
        
          <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                required
                variant="outlined"
                error={errors.ownerId ? true : false}
              />
            )}/>
             <FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label">Route Visit Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.visitType||""}
        onChange={(e)=>changeHandler("visitType", e.target.value)}
        label="Select Option"
      >
         {visitType?.map((option, optionIndex) => (
    <MenuItem key={option.routeVisitTypeID} value={option.routeVisitType}>
      {option.routeVisitType}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Route Date" minDate={tomorrow}
        maxDate={dayAfterTomorrow} value={date} sx={{width:"100%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
           
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2} >
          <Stack direction={"row"} spacing={2} sx={{width:"100%"}}>
       
    <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.schoolName} (${option?.schoolId})`}
            getOptionLabel={(option) => `${option?.schoolName}`}
              getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            disabled={loading 
              || !form.ownerId
            }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("partyId", value?.schoolId)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = party.find((option) => option.schoolName === value.schoolName);

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select School"}
                required
                variant="outlined"
                error={errors.school ? true : false}
              />
            )}/>
            <Button variant="text" disabled={!selectParty} onClick={()=>additem()}>Add</Button>
        </Stack>
        <Stack direction={"row"} spacing={2}  sx={{width:"100%"}}>
       
       <Autocomplete
               options={party1}
               // getOptionLabel={}
              //  getOptionLabel={(option) => `${option?.leadName} (${option?.leadId})`}
               getOptionLabel={(option) => `${option?.leadName}`}
                 getOptionValue={(option) => option?.leadId || ''}
               style={{ width: "100%" }}
               value={selectParty1}
               disabled={loading 
                 || !form.ownerId
               }
               noOptionsText={loading ? "Loading..." : "No option"}
                 onChange={(event, value) => {
                   console.log("TEST1212",value)
                   changeHandler("partyId", value?.leadId)
                   setSelectParty1(value)
                   //  updateDataGrid(value);
                   
                   // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                   // {
                   //     setDisableTransfer(false)
                   // }
                   // else
                   // setDisableTransfer(true)
   
                  
                   
                 }}
                 onInputChange={(e, value) => {
                   // Check if the user's input matches any option
                   const matchingOption = party.find((option) => option.schoolName === value.schoolName);
   
                  
                 }}
               renderInput={(params) => (
                 <TextField
                   {...params}
                   // disabled={
                   //   loading 
                     // || !addNewMode || props.isManager || props.isDeleted
                   // }
                   label={"Select Lead"}
                   required
                   variant="outlined"
                   error={errors.lead ? true : false}
                 />
               )}/>
               <Button variant="text" disabled={!selectParty1} onClick={()=>additem1()}>Add</Button>
           </Stack>
        </Stack>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        
        style={{ height: !dataGrid.length ? '200px' : 'auto', maxWidth:"97%" }}
        density="compact"
      //   checkboxSelection={true}
          rows={dataGrid||[]}
          columns={getColumns()}
          rowHeight={60}
          // onRowSelectionModelChange={handleSelectionModelChange}
          disableSelectionOnClick
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}          // autoPageSize
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
        />
         
       </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',margin:"20px",marginTop:"35px" }}>
      <Button variant="contained" onClick={()=>onsubmit()}>
        Create route Plan
      </Button>
      </div>
         
     
      
</div>
)}





const additem=()=>{
  console.log("SELECTEDPRODUCT",selectParty)
  const length=dataGrid.length+1
  let pushedItem={"name":selectParty.schoolName,"leadId":selectParty.schoolId,type:"Party",id:length, partyType: "1"}

    const newp = party.filter((p) => p.schoolId !== selectParty.schoolId);
    setParty(newp);
    

  setNewParty(prev => {
    return [...prev, selectParty]
  });


  setSelectParty(null)
  setDataGrid([...dataGrid,pushedItem])
}

const additem1=()=>{
    console.log("SELECTEDPRODUCT",selectParty1)
    const length=dataGrid.length+1
    let pushedItem={"name":selectParty1.leadName,"leadId":selectParty1.leadId,type:"Lead",id:length, partyType: "0"}

    const newp1 = party1.filter((p1) => p1.leadId !== selectParty1.leadId);
    setParty1(newp1);
    setNewParty1(prev => {
      return [...prev, selectParty1]
    });


    setSelectParty1(null)
    setDataGrid([...dataGrid,pushedItem])
  
  }



  const fetchEmployees=async(data)=>{
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
   }


  const validateForm = (data) => {
    const errors = {};
 
    if (!data.ownerId?.trim()) {
      errors.ownerId = "Assign to is required";
    }

    if (!dataGrid.length) {
      if (data.ownerId?.trim()) {
        if (!data.school?.trim()) {
          errors.school = "School name is required";
        }
     
        if (!data.lead?.trim()) {
          errors.lead = "Lead is required";
        }
      }
    }
      
    return errors;
  };
  
 
  const onsubmit=async(data)=>{
    try{

      const errors = validateForm(form);
      setErrors(errors);

      if(Object.keys(errors).length === 0) {
          setLoading(true);
          // const arrayOfIds = dataGrid?.map(item => item.leadId);
          const arrayOfPartyList = dataGrid?.map(item => { 
            return {
              partyId: item.leadId,
              partyType: item.partyType
            }
          });
          // const finalData = { date: form.date, ownerId: form.ownerId, oldPartyArray: arrayOfIds, visitType: form.visitType}
          const finalData = { date: form.date, ownerId: form.ownerId, partyList: arrayOfPartyList, visitType: form.visitType}
        console.log("DATAAAAAF",finalData)
        const results = await window.Platform.database.addRoutePlan(finalData);

        if (results.status) {
          window.NotificationUtils.showError(results.message);
          setLoading(false);
          return;
        }
        
        window.NotificationUtils.showSuccess("Route Plan Added Successfully!")
        setLoading(false)
        onClose()
        fetchData()
      } else {
        setLoading(false)
        window.NotificationUtils.showError("Please fill all required fields.")
      }
      
    

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }
      const getColumns = () => {
        let result = [
          {
            field: "leadName",
            headerName: "School Name",
            sortable: false,
            width: 200,
            
            editable: false,
            renderCell: (params) => {
      
              let name = params.row?.name||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "type",
            headerName: "Type",
            sortable: false,
            width: 150,
            
            editable: false,
            renderCell: (params) => {
      
              let name = params.row?.type||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
        
            {
              field: "Actions",
              headerName: "Actions",
              width: 100,
              // height: 10,
              // headerAlign: 'center',
              sortable: false,
              // editable: true,
              renderCell: (params) => {
        
                let name =params.row.total||"N/A"
        
                return (
        
                 <IconButton onClick={()=>handleDeleteEmploye(params.row)}>
<DeleteIcon/>
                 </IconButton>
        
                )
        
              },
            },
      
           
      
       
        ];
      
      
        return result
      }



      const handleDeleteEmploye = async (id) => {
        debugger
        // const response = await window.Platform.database.deleteUsers(ID);

        const deleted = newParty.filter((d) => d.schoolId === id.schoolId);
        const deleted1 = newParty1.filter((d) => d.leadId === id.leadId);
        if(id.schoolId) {
          setParty(prev => [...prev, ...deleted]);
          const dp = newParty.filter((np) => np.schoolId !== deleted[0].schoolId);
          setNewParty(dp);
        } else {
          setParty1(prev => [...prev, ...deleted1]);
          const dp1 = newParty1.filter((np1) => np1.leadId !== deleted1[0].leadId);
          setNewParty1(dp1);
        }
    
        let res = dataGrid.filter((el) => {
          return el.id != id.id;
        });
    
        setDataGrid(res);
      };

      
 
  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
        Add Route-Plan
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Route Details" />
           
            </StyledTabs>
            {tabValue==0&& renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default AddRoutePlan;