import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const AddNewRole = ({ onCancel, discardModal, loading, onSubmit, setLoading, rows, newRole, fetchRoles }) => {
    console.log("neeww",newRole)
    const [form, setForm] = useState( {
      roleId: newRole.roleId || '',
     roleName: newRole.roleName || '',
     hierarchy: newRole.hierarchy || '',
    });

    const [errors, setErrors] = useState({});
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };
const [row, setRow] = useState(rows.map((val)=>  val.roleName ))

  console.log("llll", rows)
    const submitHandler = async() => {
      // console.log("creating activity...", form);
      try{
        console.log("PRODUCT RESULTS: " , [form])
        setLoading(true)
        // console.log("PLANTCODE",plantcode[0].plant_code__c)

        const errors = validateForm(form);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
          let results = await window.Platform.database.addRole( [{ roleId: form.roleId, roleName: form.roleName.toLowerCase(), hierarchy: form.hierarchy }]);
      
      // setProduct(results.data)

          if(!results.status) {
            if (form.roleId) {
              window.NotificationUtils.showSuccess("The role has been updated successfully.");
            } else {
              window.NotificationUtils.showSuccess("The role has been added successfully.");
            }
            onCancel();
            fetchRoles();
          } else if (results.message === "Role with the same role name already exists.") {
            window.NotificationUtils.showError("A role with the same name already exists.");
          }

          setLoading(false);
        } else {
          window.NotificationUtils.showError("Please make sure to complete all required fields in the form.");
          setLoading(false);
        }
      }
      catch(err){
        console.log(err);
    setLoading(false)
     
      }
console.log(form)
    };

    const validateForm = (data) => {
      const errors = {};

      if (!data.roleName?.trim()) {
        errors.role = 'Role is required';
      }

      if (!data.hierarchy.trim()) {
        errors.reportingManager = 'Reporting manager is required';
      }

      return errors;
    };

    useEffect(()=>{
      console.log(loading)
    },[loading])
  
    return (
      <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5" gap="20px" style={{ padding: "10px" }}>
            { newRole?.roleId ? 'Edit Role' : 'Add New Role' }
          </Typography>
          <IconButton  onClick={discardModal}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack>
              <TextField
                variant="outlined"
                label="Role Name"
                defaultValue={form["roleName"]}
                onChange={(e) =>
                  changeHandler("roleName", e.target.value)
                }
                required
                error={errors.role ? true : false}
              />
            </Stack>

            <Stack>
            <Autocomplete
            options={row}
            style={{ width: "100%" }}
            value={form.hierarchy || ""}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            onChange={(e,value) => changeHandler("hierarchy", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Reporting Manager"}
                required
                variant="outlined"
                error={errors.reportingManager ? true : false}
              />)
            }
              />
              
            </Stack>
           
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                {
                  newRole?.roleId ? 'Update' : 'Create'
                }
              </Button>
            </Stack>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default AddNewRole;
  