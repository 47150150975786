import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";

import OpaqueLoading from "../../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)``;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;


const VisitTypeCheckModal = ({ onClose, status, fetchData, propData }) => {

    const [form, setForm] = useState({
        val: propData?.visitTime || "",
        id: propData?.id
    });
    const [loading, setLoading] = useState(false);

    const changeHandler = (fieldName, value) => {
        setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };

    const submitHandler = async () => {
        try {
            setLoading(true);

            if (propData) {
                await window.Platform.database.updateVisitTypeCheck({ ...form });
                window.NotificationUtils.showSuccess("Visit time check updated successfully");
            } else {
                await window.Platform.database.addVisitTypeCheck({ ...form });
                window.NotificationUtils.showSuccess("Visit time check added successfully");
            }

            setLoading(false);
            fetchData();
            onClose();
        }
        catch (err) {
            window.NotificationUtils.showError(err?.message);
            setLoading(false);
        }
    };

    return (
        <StyledDialog disableEnforceFocus maxWidth="sm" fullWidth={true} open={true}>
            <StyledDialogContent>
                <ModalHeaderContainer>
                    <Typography variant="h5">{status ? "Add Visit Time Check" : "Edit Visit Time Check"}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </ModalHeaderContainer>

                <Stack gap="20px" style={{ padding: "10px" }}>
                    {loading && <OpaqueLoading />}

                    <Stack direction={"row"} spacing={2} padding={1}>
                        <TextField label="Visit Time Check" value={form.val || ""} type="text" fullWidth onChange={(e) => changeHandler("val", e.target.value)} inputProps={{ maxLength: 250 }} />
                    </Stack>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={submitHandler}
                            disabled={!form.val[0]?.trim()?.length}
                        >
                            {propData ? 'Update' : 'Create'}
                        </Button>
                    </div>
                </Stack>
            </StyledDialogContent>
        </StyledDialog>
    );
};

export default VisitTypeCheckModal;