import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';

import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

import "./orderLineItemModal.css";
import LogoMittsure from '../Logo1.png';

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const OrderLineItemModal = ({ onCancel ,data, props }) => {
    const [dataSet, setDataSet] = useState({});
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
      const obj = props?.find((item) => item.orderId === data.id);

      const groupedData = {};

      data.data.forEach(item => {
        const seriesName = item.seriesName ? item.seriesName : '';
        if (!groupedData[seriesName]) {
          groupedData[seriesName] = [];
        }
        groupedData[seriesName].push(item);
      });

      setDataSet(obj);
      setFilteredData(groupedData);
    }, [])

    const downloadInvoice = () => {
      const date = new Date();
      const currentYear = date.getFullYear();
      const nextYear = (currentYear + 1).toString();
      const upcomingYear = nextYear?.slice(nextYear.length - 2, nextYear.length);
    
      const tableSections = Object.keys(filteredData).map(seriesName => {
        
        let name;
        if (seriesName) {
          name = seriesName;
        } else {
          name = '';
        }
        
        const tableRows = filteredData[seriesName].map(item => {
          return `
            <tr>
              <td style="width: 10%">${item?.QTY || ''}</td>
              <td style="width: 20%">${item?.sku_code || ''}</td>
              <td style="width: 40%">${item?.nameSku ? item?.nameSku : item?.product_name || ''}</td>
              <td style="width: 20%">${item?.className || ''}</td>
              <td style="width: 10%">${item?.Price || ''}</td>
            </tr>
          `;
        }).join('');
    
        return `
          <div class="table-section" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <h4 style="text-align: left;">${name}</h4>
            <table border="1" style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="width: 10%">Qty.</th>
                  <th style="width: 20%">Codes</th>
                  <th style="width: 40%">Name</th>
                  <th style="width: 20%">Class</th>
                  <th style="width: 10%">Price</th>
                </tr>
              </thead>
              <tbody>
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;
      }).join('');
    
      const htmlContent = `
        <div>
          <style>
            body {
              font-size: 12px;
            }

            h4, h5 {
              font-size: 14px;
            }
            
            table {
              font-size: 12px;
              page-break-after: auto;
            }

            .table-section {
              page-break-inside: avoid;
            }
          </style>
          <div class="logo" style="margin: 20px">
            <img src="${LogoMittsure}" alt="Logo" style="width: 150px; height: auto; margin-bottom: 20px;" />
          </div>
          <div class="main-heading" style="text-align: center; margin-top: 10px;">
            <h5>MittSure Technologies LLP</h5>
            <h4>ORDER FORM - EM - PAN INDIA (${currentYear}-${upcomingYear})</h4>
          </div>
          <div class="table-section" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
              <tbody>
                <tr>
                  <td>Bill To:- ${dataSet?.schoolName || ''}</td>
                  <td>Ship To:- ${dataSet?.Address || ''}</td>
                </tr>
                <tr>
                  <td>School Name:- ${dataSet?.schoolName || ''}</td>
                  <td>City:- ${dataSet?.state || ''}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">Transport/Travels:- ${dataSet?.transporter_name || ''}</td>
                </tr>
              </tfoot>
            </table>
          </div>
    
          ${tableSections}
    
        </div>
      `;
    
      const element = document.createElement('div');
      element.innerHTML = htmlContent;
    
      document.body.appendChild(element);

      const options = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'Order_form.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale
            : 2,
          logging
            : true,
          dpi
            : 192,
          letterRendering
            : true
        },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
      };
      html2pdf().set(options).from(element).toPdf().save('Order_form.pdf').then(() => {
        document.body.removeChild(element);
      });
    };
    

const getColumns = () => {
  let result = [
    {
      field: "itemName",
      headerName: "Item Name",
      sortable: false,
      width: 250,
      
      editable: false,
      renderCell: (params) => {
        let name = params.row?.nameSku || params.row?.product_name || "N/A";

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "QTY",
      headerName: "Quantity",
      width: 120,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name =params.row.QTY||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "Price",
        headerName: "Unit Price",
        width: 120,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.Price||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Total",
        headerName: "Total Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Total||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
     

 
  ];


  return result
}



const renderContent = () => {
  return (
      <>
        <DataGrid
          className="payrollGrid"
          sx={{ height: isMobile ? "555px" : "500px"}}
          density="compact"
          rows={data.data}
          columns={getColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8]}
        />
        <Button variant="contained" color="primary"
          onClick={()=> downloadInvoice()} sx={{ mt: 2, borderRadius: '10px' }}>
          <DownloadIcon sx={{ mr: 1 }}  />
          Download Order Form
        </Button>
      </>
  );
};
    
    // console.log("customerdata", loading)
  
  
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px",minWidth:isMobile?350:750,width:isMobile?350:750
      },
    }}>
    <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Order Line Items {/*- <Tooltip title="Order Id">{"("+ data.id+")"}</Tooltip>*/}</Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default OrderLineItemModal;
  