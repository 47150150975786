import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid } from "@mui/x-data-grid";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const PartyList = ({ onCancel ,data }) => {
   
console.log("DATAUTH",data)



const getColumns = () => {
  let result = [
    {
      field: "itemId",
      headerName: "School Name",
      sortable: false,
      width: 200,
      
      editable: false,
      renderCell: (params) => {

        let name = params.row?.schoolName?params.row?.schoolName:params.row?.leadName||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "lead",
        headerName: "Type",
        sortable: false,
        width: 200,
        
        editable: false,
        renderCell: (params) => {
  
          let name = (params.row?.schoolName ? "School" : "Lead") || "N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    {
      field: "distance",
      headerName: "Distance",
      sortable: false,
      width: 200,
      editable: false,
      renderCell: (params) => {
        let distance = params.row?.distance + ' KM'
        return (
          <Tooltip title={distance}>
            <Typography variant="inherit">{distance}</Typography>
          </Tooltip>
        )
      },
    }
  ];


  return result
}



const renderContent = () => {
  return (
    // <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        className="payrollGrid"
      //   checkboxSelection={true}
      sx={{height:isMobile ? "555px":"522px"}}
      density="compact"
        rows={data.data}
        columns={getColumns()}
        rowHeight={60}
        disableSelectionOnClick
        disableRowSelectionOnClick
        autoPageSize
        pageSizeOptions={[10]}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
    // </Box>
  );
};
    
    // console.log("customerdata", loading)
  
  
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px",minWidth:isMobile?350:750,width:isMobile?350:750
      },
    }}>
    <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Route Plan Party - <Tooltip title="Route Date ">{"("+ data.date+")"}</Tooltip></Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default PartyList;
  